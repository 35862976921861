import * as React from 'react';
import "./CSS/Login.css";
import { useState } from 'react';
import {
    useNavigate,
    useLocation
  } from "react-router-dom";
export default function Login({ setEmail, setPassword, handleLogin, handleGoogleLogin, handleAppleLogin, setIsRememberMe, isRememberMe }) {
    const navigate = useNavigate();
    const location = useLocation();
    const from = new URLSearchParams(location.search).get('from') || '/';
  console.log(isRememberMe)
  console.log("isRememberMe")
  const hrefToRegister = () => {
    navigate(`/register?from=${from}`)
  }

  // const handleOnLogin = (event) => {
  //   // event.preventDefault(); // Prevent default form submission
  //   console.log("isRememberMe")
  //   console.log(isRememberMe)
  //   // Call the imported handleLogin function, passing the isChecked value
  //   handleLogin(isRememberMe);
  // };

    return (
        <div className="loginContent">
        <div className="loginBox">
          <h4 className="loginTitle">Login</h4>
          {/* <p className="loginSubTitle">Login here using Google account/Apple ID or email and password</p> */}
          <p className="loginSubTitle">Login here using your email and password</p>
          {/* <form onSubmit={handleAction} action={handleAction}> */}
          <div className="inputGroup">
            <label className="inputGroupLabel" htmlFor="InputEmailCard1">Your Email</label>
            <div className="inputContainer">
              {/* <span class="input-group-text " id="basic-addon1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="#ffffff" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
                </svg>
              </span> */}
              <input
                className="loginInput"
                type="email"
                id="InputEmailCard1"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="inputGroup">
          <label className="inputGroupLabel" htmlFor="InputPasswordCard1">Your Password</label>
            <div className="inputContainer">
              <input
                className="loginInput"
                type="password"
                id="InputPasswordCard1"
                onChange={(e) => setPassword(e.target.value)}
              />
    
            </div>
          </div>
          {/* <div class="remember-me">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="defaultCheck5" value=""></input>
              <label className="form-check-label" htmlFor="defaultCheck5">Remember me</label>
            </div>
          </div> */}
          <div className="rememberMe">
            <input
              className="rememberInput"
              id="rememberInput"
              type="checkbox"
              checked={isRememberMe} // Set the checked property based on state
              onChange={() => setIsRememberMe(!isRememberMe)} // Toggle the checked state on change
              // checked={isChecked}
              // onChange={setIsChecked(!isChecked)}
            ></input>
            <label htmlFor="rememberInput">Remember me</label>
          </div>
          <button type="submit" className="loginButton" onClick={handleLogin}>
            Log in
          </button>
          {/* <div className="orLoginWithLine"></div> */}
          <p className="orLoginWith">OR</p>
          <button type="submit" className="googleLoginButton" onClick={handleGoogleLogin}>
              <div className="c_gp c_bc c_b">
                  <svg width="15" height="18" version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/1999/xlink"
                       className="googleLoginSVG">
                      <path fill="#EA4335"
                            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                      <path fill="#4285F4"
                            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                      <path fill="#FBBC05"
                            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                      <path fill="#34A853"
                            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                  </svg>
                  {/*<span className="googleLoginSpan">Log in with Google</span>*/}
                  <span className="googleLoginSpan">Continue with Google</span>
              </div>
          </button>
            {/*<button type="submit" className="appleLoginButton" onClick={handleAppleLogin}>*/}
          {/*  <div className="c_gp c_bc c_b">*/}
          {/*    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" className="appleLoginSVG">*/}
          {/*      <path d="M7.31054 4.48641C8.0927 4.48641 9.07315 3.95762 9.65701 3.25258C10.1858 2.61363 10.5714 1.72131 10.5714 0.82899C10.5714 0.707811 10.5604 0.586631 10.5383 0.487484C9.66803 0.520533 8.62148 1.07135 7.99355 1.80944C7.49781 2.37128 7.04614 3.25258 7.04614 4.15592C7.04614 4.28811 7.06818 4.42031 7.07919 4.46438C7.13427 4.47539 7.22241 4.48641 7.31054 4.48641ZM4.55646 17.8162C5.62504 17.8162 6.09874 17.1001 7.43172 17.1001C8.78672 17.1001 9.08416 17.7941 10.2739 17.7941C11.4417 17.7941 12.2238 16.7145 12.9619 15.657C13.7881 14.4452 14.1296 13.2554 14.1517 13.2003C14.0746 13.1783 11.8382 12.2639 11.8382 9.69713C11.8382 7.47183 13.6009 6.46935 13.7 6.39223C12.5323 4.71775 10.7586 4.67369 10.2739 4.67369C8.96298 4.67369 7.8944 5.46686 7.22241 5.46686C6.49533 5.46686 5.53691 4.71775 4.40223 4.71775C2.24303 4.71775 0.0507812 6.5024 0.0507812 9.87339C0.0507812 11.9665 0.865989 14.1808 1.86847 15.6129C2.72775 16.8247 3.47686 17.8162 4.55646 17.8162Z" fill="#000000"></path>*/}
          {/*    </svg>*/}
          {/*    <span className="appleLoginSpan">Log in with Apple</span>*/}
          {/*  </div>*/}
          {/*</button>*/}
          <p className="doNotHaveAccount">Not registered? <span onClick={hrefToRegister}>Create account</span> </p>
        </div>
        </div>
    );
}