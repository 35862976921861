// import logo from './logo.svg';
import React, { useEffect } from 'react'
import { useState } from 'react';
import './App.css';
import Login from './components/pages/Login';
import Register from './components/pages/Register';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { app, database } from './firebase-config';
import { getDatabase, ref, set, child, get } from "firebase/database";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, OAuthProvider, onAuthStateChanged, setPersistence } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

function App() {
  const authentication = getAuth(); //important
  let navigate = useNavigate();
  const currLocation = useLocation().pathname

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRememberMe, setIsRememberMe] = useState(false);

  useEffect(() => {
    console.log(currLocation)
    // let authToken = sessionStorage.getItem('Auth Token')

    // if (authToken) {
      if (currLocation === "/") {
        navigate('/login')
      }
    // }
  }, [])
//LOGIN
  const location = useLocation();
  const from = new URLSearchParams(location.search).get('from') || '/';
  // const handleLogin = () => {
  //   // const webId = 'todo'
  //   console.log(from)
  //   signInWithEmailAndPassword(authentication, email, password)
  //     .then((response) => {
  //       // navigate('/')
  //       console.log("logged in!")
  //       const token = authentication.currentUser.getIdToken();
  //
  //       // Redirect back to the original app with the token
  //       // window.location.href = `${from}?token=${token}`;
  //       console.log(token)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       if (error.code === 'auth/invalid-credential') {
  //         // toast("error")
  //         //   toast.error('Invalid credentials', {
  //         //       position: "top-right",
  //         //       autoClose: 5000,
  //         //       hideProgressBar: false,
  //         //       closeOnClick: true,
  //         //       pauseOnHover: true,
  //         //       draggable: true,
  //         //       progress: undefined,
  //         //       theme: "dark",
  //         //       transition: Bounce,
  //         //   });
  //       }
  //       // enqueueSnackbar('Please check the credential!', 'error');
  //         toast.error('Invalid credentials', {
  //             position: "top-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: false,
  //             pauseOnHover: false,
  //             draggable: false,
  //             progress: undefined,
  //             theme: "dark",
  //             transition: Bounce,
  //         });
  //     })
  // }
  // console.log(document.getElementById("rememberInput").value);
  const handleLogin = async () => {
    try {
      console.log(isRememberMe)
      const response = await signInWithEmailAndPassword(authentication, email, password);
      const token = await authentication.currentUser.getIdToken(true);
      const currentUID = authentication.currentUser.auth.currentUser.uid || response.user.uid;
      // const rememberMe = document.getElementById("rememberInput").checked;
      // console.log(rememberMe);
      window.location.href = `${from}?token=${token}&rememberMe=${isRememberMe}&user=${currentUID}`;
    } catch (error) {
      console.log(error);
      if (error.code === 'auth/invalid-credential') {
        toast.error('Invalid credentials', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      }
    }
  };


  // const handleGoogleLogin = () => {
  //   const provider = new GoogleAuthProvider();
  //   signInWithPopup(authentication, provider)
  //     .then((result) => {
  //       const user = result.user;
  //       console.log("Google login successful!");
  //       const token = result.user.getIdToken();
  //
  //       // Redirect back to the original app with the token
  //       window.location.href = `${from}?token=${token}`;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       console.log('Google login failed!');
  //     });
  // };
  const handleGoogleLogin = async (rememberMe) => {
    try {
      const provider = new GoogleAuthProvider();
      const response = await signInWithPopup(authentication, provider);
      const token = await authentication.currentUser.getIdToken(true);
      // const rememberMe = document.getElementById("rememberInput").checked;
      // console.log(rememberMe);
      window.location.href = `${from}?token=${token}&rememberMe=${rememberMe}`;
    } catch (error) {
      console.log(error);
      console.log('Google login failed! Please contact our support');
    }
  };

  const handleAppleLogin = () => {
    const provider = new OAuthProvider('apple.com');
    signInWithPopup(authentication, provider)
      .then((result) => {
        const user = result.user;
        console.log("Apple login successful!");
        sessionStorage.setItem('Auth Token', result._tokenResponse.refreshToken);
        sessionStorage.setItem('User', user.uid);
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
        console.log('Apple login failed!');
      });
  }

  //REGISTER
  // const handleRegister = () => {
  //   createUserWithEmailAndPassword(authentication, email, password)
  //   .then((response) => {
  //     navigate('/')
  //     sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
  //     sessionStorage.setItem('User', authentication.currentUser.uid)
  //     const db = getDatabase();
  //     set(ref(db, 'users/' + authentication.currentUser.uid), {
  //       firstname: firstname,
  //       lastname: lastname,
  //       email: email
  //       // profile_picture : imageUrl
  //     });
  //   })
  // }

  const handleRegister = async () => {
    try {
      const response = await createUserWithEmailAndPassword(authentication, email, password)
      const token = await authentication.currentUser.getIdToken(true);
      const db = getDatabase();
      await set(ref(db, 'users/' + authentication.currentUser.uid), {
        firstname: firstname,
        lastname: lastname,
        email: email
        // profile_picture : imageUrl
      });
      window.location.href = `${from}?token=${token}`;
    } catch (error) {
      console.log(error);
    }
  };

  // const handleGoogleRegister = () => {
  //   const provider = new GoogleAuthProvider();
  //   signInWithPopup(authentication, provider)
  //     .then((result) => {
  //       const user = result.user;
  //       sessionStorage.setItem('Auth Token', result._tokenResponse.refreshToken);
  //       sessionStorage.setItem('User', user.uid);
  //       const db = getDatabase();
  //       set(ref(db, 'users/' + user.uid), {
  //         firstname: user.displayName.split(' ')[0], // Assuming the first part of displayName is the first name
  //         lastname: user.displayName.split(' ')[1], // Assuming the second part of displayName is the last name
  //         email: user.email
  //       });
  //       navigate('/');
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  const handleGoogleRegister = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const response = await signInWithPopup(authentication, provider)
      const user = response.user;
      const token = await authentication.currentUser.getIdToken(true);
      const db = getDatabase();
      await set(ref(db, 'users/' + user.uid), {
        firstname: user.displayName.split(' ')[0], // Assuming the first part of displayName is the first name
        lastname: user.displayName.split(' ')[1], // Assuming the second part of displayName is the last name
        email: user.email
      });
      window.location.href = `${from}?token=${token}`;
    } catch (error) {
      console.log(error);
      console.log('Google login failed! Please contact our support');
    }
  };

  const handleAppleRegister = () => {
    const provider = new OAuthProvider('apple.com');
    signInWithPopup(authentication, provider)
      .then((result) => {
        const user = result.user;
        sessionStorage.setItem('Auth Token', result._tokenResponse.refreshToken);
        sessionStorage.setItem('User', user.uid);
        const db = getDatabase();
        set(ref(db, 'users/' + user.uid), {
          firstname: user.displayName ? user.displayName.split(' ')[0] : '', // Check if displayName exists
          lastname: user.displayName ? user.displayName.split(' ')[1] : '', // Check if displayName exists
          email: user.email
        });
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="App">
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            theme="dark"
        />
        {/* Same as */}
        <ToastContainer />
      <>
        <Routes>
          <Route
            path='/login'
            element={
              <Login 
                setEmail={setEmail}
                setPassword={setPassword}
                setIsRememberMe={setIsRememberMe}
                isRememberMe={isRememberMe}
                handleLogin={() => handleLogin()}
                handleGoogleLogin={() => handleGoogleLogin()}
                handleAppleLogin={() => handleAppleLogin()}
              />}
          />
          <Route
            path='/register'
            element={
              <Register 
                setEmail={setEmail}
                setPassword={setPassword}
                setFirstname={setFirstname}
                setLastname={setLastname}
                handleRegister={() => handleRegister()}
                handleGoogleRegister={() => handleGoogleRegister()}
                handleAppleRegister={() => handleAppleRegister()}
              />}
          />
        </Routes>
      </>
    </div>
  );
}

export default App;
