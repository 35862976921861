// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getDatabase } from "firebase/database";
// // https://firebase.google.com/docs/web/setup#available-libraries
//
// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyBc5D2i53Ou83DeAHoh3Gi7KVef5dOwCcA",
//     authDomain: "jiricech2059-auth.firebaseapp.com",
//     projectId: "jiricech2059-auth",
//     storageBucket: "jiricech2059-auth.appspot.com",
//     messagingSenderId: "754769936965",
//     appId: "1:754769936965:web:e43b39f485ac07e29a592c",
//     measurementId: "G-9BMWZY73KC",
//     databaseURL: "https://jiricech2059-auth-default-rtdb.europe-west1.firebasedatabase.app/"
// };
//
// const app = initializeApp(firebaseConfig);
// const database = getDatabase(app);
//
// // Initialize Firebase
// // const app = initializeApp(firebaseConfig);
// // const analytics = getAnalytics(app);
// export { app, database }


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCuxAlKGhPt5OXRGAOPORv_C56ErkWRxCs",
    authDomain: "jiricech2059-image-finder.firebaseapp.com",
    databaseURL: "https://jiricech2059-image-finder-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "jiricech2059-image-finder",
    storageBucket: "jiricech2059-image-finder.appspot.com",
    messagingSenderId: "767148203244",
    appId: "1:767148203244:web:faf609359f50173a69d9c9",
    measurementId: "G-DC4MZPL6T5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);